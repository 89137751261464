import '../sass/style.scss';

(function () {
	if (typeof (NodeList).prototype.forEach !== 'function') {
		(NodeList).prototype.forEach = Array.prototype.forEach;
	}

	return false;
})();

import cookies from './components/cookies';
import header from './components/header';
import bannerVideo from './components/bannerVideo';
import career from './components/career';
import slider from './components/autoparkSlider';
import map from './components/map';
import calculator from './components/calculator';

const components = [
	{
		class: cookies,
		selector: '.js-cookies',
	},
	{
		class: header,
		selector: '.js-header',
	},
	{
		class: bannerVideo,
		selector: '.js-banner-video',
	},
	{
		class: career,
		selector: '.js-career-modal',
	},
	{
		class: slider,
		selector: '.js-autopark-slider',
	},
	{
		class: map,
		selector: '#map',
	},
	{
		class: calculator,
		selector: '.js-calculator'
	}
];

components.forEach(component => {
    if (document.querySelector(component.selector) !== null) {
        document.querySelectorAll(component.selector).forEach(
            element => new component.class(element, component.options)
        )
    }
});