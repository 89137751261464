import axios from 'axios';

export default class career {
	constructor(element) {
		let careerForm = element.querySelector('.js-career-form');
		let formInputs = element.querySelectorAll('.js-career-input');
		let openModalBtns = document.querySelectorAll('.js-open-modal');
		let closeModalBtn = document.querySelector('.js-career-close');
		let hiddenFileInput = element.querySelector('.js-hidden-file-input');

		hiddenFileInput.addEventListener('change', () => {
			if(hiddenFileInput.files[0]){
				let value = hiddenFileInput.value;
				value = value.split(/(\\|\/)/g).pop();
				element.querySelector('.js-file-input').value = value;
			} else
				element.querySelector('.js-file-input').value = '';
		});

		closeModalBtn.addEventListener('click', () => {
			this.closeModal();
		});

		openModalBtns.forEach((btn) => {
			btn.addEventListener('click', () => {
				this.openModal(btn.textContent);
			});
		});

		careerForm.addEventListener('submit', (e) => {
			e.preventDefault(element);
			this.sendFormData(e, formInputs, hiddenFileInput);
		});

		formInputs.forEach((input) => {
			let inputCol = input.parentNode;
			input.addEventListener('focus', () => {
				inputCol.classList.add('modal__form-col--focus');
				input.classList.remove('modal__form-input--error');
			});

			input.addEventListener('blur', () => {
				if(!input.value){
					inputCol.classList.remove('modal__form-col--focus');
				}
			});

			input.addEventListener('click', () => {
				if(input.classList.contains('js-file-input')){
					hiddenFileInput.click();
				}
			});
		});

	}

	sendFormData(e, formInputs, hiddenFileInput){
		let valid = this.checkFormValidation(formInputs);
		if(valid){
			const formData = new FormData(e.target);
			axios({
				method: 'post',
				url: '/endpoint/careers',
				processData: false,
				contentType: false,
				data: formData
			})
			.then((response) => {
				if(response.data == 'success'){
					formInputs.forEach((input) => {
						input.value = '';
						this.closeModal();
					});
				}
			});
		}
	}

	checkFormValidation(formInputs){
		let valid = true;
		formInputs.forEach((input) => {
			if(input.classList.contains('js-input-required') && !input.value){
				input.classList.add('modal__form-input--error');
				valid = false;
			}

			if(input.classList.contains('js-input-email')){
				valid = this.validateEmailType(input.value);
				if(!valid){
					input.classList.add('modal__form-input--error');
				}
			}
			else if(input.classList.contains('js-input-phone')){
				valid = this.validatePhoneType(input.value);
				if(!valid){
					input.classList.add('modal__form-input--error');
				}
			}
		});

		return valid;
	}

	validateEmailType(input){
		let emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
		return emailRegex.test(input);
	}

	validatePhoneType(input){
		let phoneRegex = new RegExp(/^(?=.*[0-9])[- +()0-9]+$/);
		return phoneRegex.test(input);
	}

	openModal(modalTitle){
		let modal = document.querySelector('.js-career-modal');
		let title = modal.querySelector('.js-career-modal-title');
		let modalTypeInput = modal.querySelector('[name="resume-type"]');

		title.textContent = modalTitle;
		modalTypeInput.value = modalTitle;

		document.body.classList.add('hidden');
		document.querySelector('html').classList.add('hidden');
		modal.classList.add('modal--show');
		setTimeout(() => {
			modal.classList.add('modal--anim');
			setTimeout(() => {
				let content = document.querySelector('.js-career-modal-content');
				content.classList.add('modal__content--anim');
			}, 300);
		}, 50);
	}

	closeModal(){
		let modal = document.querySelector('.js-career-modal');
		modal.classList.remove('modal--anim');
		document.body.classList.remove('hidden');
		document.querySelector('html').classList.remove('hidden');

		setTimeout(() => {
			let content = document.querySelector('.js-career-modal-content');
			modal.classList.remove('modal--show');
			content.classList.remove('modal__content--anim');
		}, 500);
	}
}