import axios from 'axios';

export default class calculator {
  constructor(element) {
    const button = element.querySelector('.calculator__single--button button');
    const result = element.querySelector('.calculator__single--result');
    this.fullCarLdmMin = 12.7;
    this.fullCarLdmMax = 15.4;
    this.fullCarWeightMin = 24;
    this.translations = JSON.parse( button.getAttribute('data-calc-translations') );
    let transitForm = document.querySelector('.js-transit-form');
		let formInputs = document.querySelectorAll('.js-transit-input');
		let openModalBtns = document.querySelectorAll('.js-open-modal');
		let openModalBtnsSimple = document.querySelectorAll('.js-open-modal-simple');
    let closeModalBtn = document.querySelector('.js-transit-close');
    let departuresElement = document.querySelector('.js-departures');
    let targetsElement = document.querySelector('.js-targets');

    button.addEventListener('click', () => {
      this.calculateResult(result);
    })
  
    closeModalBtn.addEventListener('click', () => {
      this.closeModal();
    });

    transitForm.addEventListener('submit', (e) => {
      e.preventDefault();
			this.sendFormData(e, formInputs);
		});
    
    formInputs.forEach((input) => {
			let inputCol = input.parentNode;
			input.addEventListener('focus', () => {
				inputCol.classList.add('modal__form-col--focus');
				input.classList.remove('modal__form-input--error', 'modal__form-textarea--error', 'modal__form-select--error');
			});

			input.addEventListener('blur', () => {
				if(!input.value){
					inputCol.classList.remove('modal__form-col--focus');
				}
			});

    });

    departuresElement.addEventListener('change', function() {
      if( this.value == 'Kita' )
        $(".js-other-origin-input").show().find('[name=origin_country_other]').addClass('js-input-required');
      else
        $(".js-other-origin-input").hide().find('[name=origin_country_other]').removeClass('js-input-required');
    });

    targetsElement.addEventListener('change', function() {
      if( this.value == 'Kita' )
        $(".js-other-destination-input").show().find('[name=destination_country_other]').addClass('js-input-required');
      else
        $(".js-other-destination-input").hide().find('[name=destination_country_other]').removeClass('js-input-required');
    });

    document.querySelectorAll('.js-open-modal-simple').forEach((btn) => {
      btn.addEventListener('click', (e) => {
        e.preventDefault();
        this.openModalSimply();
      });
    });
    
  }

  sendFormData(e, formInputs){
		let valid = this.checkFormValidation(formInputs);
		if(valid){
			const formData = new FormData(e.target);
			axios({
				method: 'post',
				url: '/endpoint/calculator/form',
				processData: false,
				contentType: false,
				data: formData
			})
			.then((response) => {
				if(response.data == 'success'){
          this.formSuccessReset(formInputs);
          
          let modal = document.querySelector('.js-transit-modal');
          if (modal.classList.contains('no-data')) {
            let success_wrap = document.querySelector('.js-content-show-on-success');
            let content_wrap = document.querySelector('.js-content-hide-on-success');
            content_wrap.style.display = 'none';
            success_wrap.style.display = 'block';
          } else {
            this.closeModal();
            var result = document.querySelector('.calculator__single--result');
            result.innerHTML = '';
            var string = '<p>'+ this.translations.calc_form_success01 +'</p>';
            if(this.translations.calc_form_success02)
              string = string + '<p>'+ this.translations.calc_form_success02 +'</p>';
            result.innerHTML = string;
            document.querySelector('.js-calculator').scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
				}
			});
		}
  }
  
  formSuccessReset(formInputs){
    let weightElement = document.querySelector('.js-weight'),
        departuresElement = document.querySelector('.js-departures'),
        targetsElement = document.querySelector('.js-targets'),
        checkboxes = document.querySelectorAll('.modal__form-checkbox');
        
    weightElement.value = '';
    departuresElement.options[0].selected = true; 
    targetsElement.options[0].selected = true; 
    formInputs.forEach((input) => {
      input.value = '';
    });
    checkboxes.forEach((input) => {
      input.checked = false;
    });
    document.querySelector('[data-calc-meters]').value = '';
    document.querySelector('[data-calc-weight]').value = '';
  }

	checkFormValidation(formInputs){
		let valid = true;
		formInputs.forEach((input) => {
			if(input.classList.contains('js-input-required') && !input.value){
        input.classList.add('modal__form-input--error');
        if(input.classList.contains('js-input-textarea')) {
          input.classList.add('modal__form-textarea--error');
        }
				valid = false;
			}

			if(input.classList.contains('js-input-email')){
				valid = this.validateEmailType(input.value);
				if(!valid){
					input.classList.add('modal__form-input--error');
				}
			}
			else if(input.classList.contains('js-input-phone')){
				valid = this.validatePhoneType(input.value);
				if(!valid){
					input.classList.add('modal__form-input--error');
				}
			}
			else if(input.classList.contains('js-input-select')){
				valid = this.validateSelectType(input.value);
				if(!valid){
					input.classList.add('modal__form-select--error');
				}
			}
		});

		return valid;
	}

	validateEmailType(input){
		let emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
		return emailRegex.test(input);
	}

	validatePhoneType(input){
		let phoneRegex = new RegExp(/^(?=.*[0-9])[- +()0-9]+$/);
		return phoneRegex.test(input);
	}

	validateSelectType(input){
    if(input == 0)
      return false;
    else
      return true;
	}

  openModal(calcStack){
    let modal = document.querySelector('.js-transit-modal'),
        elemScrollTop = document.querySelector('html').scrollTop,
        documentClientHeight = document.querySelector('html').clientHeight,
        documentHeight = document.querySelector('html').offsetHeight,
        modalOffset = ((elemScrollTop + documentClientHeight) * 100) / documentHeight;

    document.querySelector('.modal__content--transit').style.cssText = 'top: '+ modalOffset + '%';

    this.fillForm(calcStack);

    modal.classList.add('modal--show');
    modal.classList.remove('no-data');
		setTimeout(() => {
			modal.classList.add('modal--anim');
			setTimeout(() => {
				let content = document.querySelector('.js-transit-modal-content');
				content.classList.add('modal__content--anim');
			}, 300);
		}, 50);
  }

  openModalSimply(){
    let modal = document.querySelector('.js-transit-modal'),
        elemScrollTop = document.querySelector('html').scrollTop,
        documentClientHeight = document.querySelector('html').clientHeight,
        documentHeight = document.querySelector('html').offsetHeight,
        modalOffset = ((elemScrollTop + documentClientHeight) * 100) / documentHeight;

    document.querySelector('.modal__content--transit').style.cssText = 'top: '+ (modalOffset-4) + '%';

		modal.classList.add('modal--show');
		modal.classList.add('no-data');
		setTimeout(() => {
			modal.classList.add('modal--anim');
			setTimeout(() => {
				let content = document.querySelector('.js-transit-modal-content');
				content.classList.add('modal__content--anim');
			}, 300);
		}, 50);
  }

  fillForm(calcStack) {
    let weightElement = document.querySelector('.js-weight'),
        departuresElement = document.querySelector('.js-departures'),
        targetsElement = document.querySelector('.js-targets');

    weightElement.value = calcStack.weight_val;
    weightElement.focus();
    weightElement.parentNode.classList.add('modal__form-col--focus');

    if(departuresElement.options.length > 0) {
      for(var i = 0; i < departuresElement.options.length; i++) {
        if(departuresElement.options[i].label == calcStack.departures_label) {
          departuresElement.options[i].selected = true; 
        }
      }
    }
    
    if(targetsElement.options.length > 0) {
      for(var i = 0; i < targetsElement.options.length; i++) {
        if(targetsElement.options[i].label == calcStack.targets_label) {
          targetsElement.options[i].selected = true; 
        }
      }
    }
  }
  
  closeModal(){
		let modal = document.querySelector('.js-transit-modal');
		modal.classList.remove('modal--anim');
		document.body.classList.remove('hidden');
    document.querySelector('html').classList.remove('hidden');

		setTimeout(() => {
			let content = document.querySelector('.js-transit-modal-content');
			modal.classList.remove('modal--show');
      content.classList.remove('modal__content--anim');
      
      if (modal.classList.contains('no-data')) {
        let success_wrap = document.querySelector('.js-content-show-on-success');
        let content_wrap = document.querySelector('.js-content-hide-on-success');
        content_wrap.style.display = 'block';
        success_wrap.style.display = 'none';
      }
      
		}, 500);
	}

  calculateResult(element) {

    let calcStack = [];
    calcStack.departures = $('[data-calc-departures]'),
    calcStack.departures_label = $('option:selected', calcStack.departures).text(),
    calcStack.departures_lon = $('option:selected', calcStack.departures).attr('data-lon'),
    calcStack.departures_lat = $('option:selected', calcStack.departures).attr('data-lat'),
    calcStack.targets = $('[data-calc-targets]'),
    calcStack.targets_label = $('option:selected', calcStack.targets).text(),
    calcStack.targets_lon = $('option:selected', calcStack.targets).attr('data-lon'),
    calcStack.targets_lat = $('option:selected', calcStack.targets).attr('data-lat'),
    calcStack.weight = $('[data-calc-weight]'),
    calcStack.weight_val = parseFloat($('[data-calc-weight]').val()),
    calcStack.weight_min = parseFloat(calcStack.weight.attr('data-calc-weight-min')),
    calcStack.weight_max = parseFloat(calcStack.weight.attr('data-calc-weight-max')),
    calcStack.meters = $('[data-calc-meters]'),
    calcStack.meters_val = parseFloat($('[data-calc-meters]').val()),
    calcStack.meters_min = parseFloat(calcStack.meters.attr('data-calc-meters-min')),
    calcStack.meters_max = parseFloat(calcStack.meters.attr('data-calc-meters-max'));

    this.resetErrors( element );
    let is_valid = this.validateInput( calcStack );

    if( is_valid == true ) {
      let days = this.getTransit( calcStack );
      this.setResult( days, element, calcStack );
      element.classList.add('active');
    } else {
      this.setErrors( element );
      element.classList.add('active', 'error');
    }

  }

  getTransit( calcStack ) {
    let f1 = calcStack.departures_lon * Math.PI / 180,
		    f2 = calcStack.targets_lon * Math.PI / 180,
		    l1 = calcStack.departures_lat * Math.PI / 180,
		    l2 = calcStack.targets_lat * Math.PI / 180,
		    df = (f2-f1),
		    dl = (l2-l1);
		
		let a = Math.sin(df/2) * Math.sin(df/2) +
			Math.cos(f1) * Math.cos(f2) *
			Math.sin(dl/2) * Math.sin(dl/2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));	
    
    let isFullCar = this.isFullCar( calcStack );

    let realDays = Math.ceil(6371 * c / 650 + 1.5);

    if( isFullCar == true ) { // + [-1; 1] days to basic transit
      if( realDays <= 1 ) // if 0 or 1 day
          var totalTransit = 1;
        else
          var totalTransit = (realDays-1) +'-'+ (realDays+1);
    } else { //  + [-1; 2] days to basic transit
      if( realDays <= 1 ) // if 0 or 1 day
        var totalTransit = 1 +'-'+ 2;
      else
        var totalTransit = (realDays-1) +'-'+ (realDays+2);
    }

    return totalTransit;
  }

  isFullCar( calcStack ) {
    if( (calcStack.meters_val >= this.fullCarLdmMin && calcStack.meters_val <= this.fullCarLdmMax) || calcStack.weight_val > this.fullCarWeightMin )
      return true;
    else
      return false
  }

  setResult( days, element, calcStack ) {
    let string = '';
    element.innerHTML = '';
    string = string + '<p>'+ this.translations.calc_transit_label01 +' <b>'+days+'</b> '+ this.translations.calc_transit_label02 +'</p>';
    string = string + '<p>'+ this.translations.calc_explain01 +'</p>';
    if( this.translations.calc_explain02 )
      string = string + '<p>'+ this.translations.calc_explain02 +'</p>';
    string = string + '<p><button class="small-btn js-open-modal">'+ this.translations.calc_form_btn +'</button></p>';
    element.innerHTML = string;

    document.querySelectorAll('.js-open-modal').forEach((btn) => {
      btn.addEventListener('click', () => {
        this.openModal(calcStack);
      });
    });

  }

  setErrors( element ) {
    element.innerHTML = '';

    var string = '<b>'+ this.translations.calc_error_label01 +'</b><br/><br/>';
    for(var k in this.errors) {
      string = string + this.errors[k][1]+'<br/>';
      $( this.errors[k][0] ).addClass('error'); 
    } 

    element.innerHTML = string;
  }

  resetErrors( element ) {
    element.classList.remove('active');
    element.classList.remove('error');
    if( this.errors ) {
      for(var k in this.errors) {
        $( this.errors[k][0] ).removeClass('error'); 
      } 
    }
  }

  validateInput( calcStack ) {
    let is_valid = true;

    this.errors = [];
      
    if( calcStack.departures_lon == undefined || calcStack.departures_lat == undefined ) {
      is_valid = false;
      this.errors.push([ calcStack.departures, this.translations.calc_field01 ]);
    }

    if( calcStack.targets_lon == undefined || calcStack.targets_lat == undefined ) {
      is_valid = false;
      this.errors.push([ calcStack.targets, this.translations.calc_field02 ]);
    }

    if( (calcStack.weight_val < calcStack.weight_min || calcStack.weight_val > calcStack.weight_max) || Number.isNaN(calcStack.weight_val) ) {
      is_valid = false;
      this.errors.push([ calcStack.weight, this.translations.calc_field03 +' Min.: '+calcStack.weight_min+ ', maks.: '+calcStack.weight_max ]);
    }

    if( (calcStack.meters_val < calcStack.meters_min || calcStack.meters_val > calcStack.meters_max) || Number.isNaN(calcStack.meters_val) ) {
      is_valid = false;
      this.errors.push([ calcStack.meters, this.translations.calc_field04 +' Min.: '+calcStack.meters_min+ ', maks.: '+calcStack.meters_max ]);
    }

    return is_valid;
  }

}
