export default class header {
	constructor(element) {
		let currentLangBtn = element.querySelector('.js-langs-click');
		let list = document.querySelector('.js-langs-list');
		let menu = document.querySelector('.js-menu');
		let menuItemsWithSubmenu = document.querySelectorAll('.header__list-item--sublist');
		let submenuBacks = document.querySelectorAll('.header__sublist-back');
		const headerBlock = document.querySelector('.header__right');

		submenuBacks.forEach((btn) => {
			btn.addEventListener('click', (e) => {
				let submenu = document.querySelector('.header__sublist-wrap--show');
				submenu.classList.remove('header__sublist-wrap--show');
				headerBlock.classList.remove('header__right--stop-scrolling');
				e.stopPropagation();
			});
		});

		menuItemsWithSubmenu.forEach((btn) => {
			btn.addEventListener('click', () => {
				if( window.innerWidth < 1001 ) {
					let submenu = btn.querySelector('.header__sublist-wrap');
					submenu.classList.add('header__sublist-wrap--show');
					headerBlock.classList.add('header__right--stop-scrolling');
					headerBlock.scrollTo(0, 0);
				}
			});
		});

		menu.addEventListener('click', () => {
			this.showHideMenu(menu);
		});

		currentLangBtn.addEventListener('click', () => {
			this.slideDownLangs(list);
		});

		document.addEventListener('click', (e) => {
			this.checkIfClickedOutside(list, element, e);
		});

		window.addEventListener('scroll', () => {
			this.windowScroll(element);
		});
	}

	showHideMenu(menu){
		let headerMenu = document.querySelector('.js-header-mobile-menu');

		if(menu.classList.contains('header__menu--active')){
			document.body.classList.remove('hidden');
			document.querySelector('html').classList.remove('hidden');
			menu.classList.remove('header__menu--active');
			headerMenu.classList.remove('header__right--anim');
			setTimeout(() => {
				headerMenu.classList.add('header__right--show');
			}, 300);
		}
		else{
			menu.classList.add('header__menu--active');
			headerMenu.classList.add('header__right--show');
			document.body.classList.add('hidden');
			document.querySelector('html').classList.add('hidden');
			setTimeout(() => {
				headerMenu.classList.add('header__right--anim');
			}, 50);
		}
	}

	slideDownLangs(list){
		list.classList.toggle('header__lang-list--show');
	}

	checkIfClickedOutside(list, element, e){
		let clicked = element.contains(e.target);
		if (!clicked){
			list.classList.remove('header__lang-list--show');
		}
	}

	windowScroll(element){
		let wScroll = window.scrollY;
		if(wScroll <= 0){
			element.classList.remove('header--scrolled');
		}
		else{
			element.classList.add('header--scrolled');
		}
	}
}