import { Swiper, Navigation, Pagination, Scrollbar } from 'swiper/dist/js/swiper.esm.js';
Swiper.use([Navigation, Pagination, Scrollbar]);

export default class autoparkSlider {
	constructor(element) {
		this.photosSlider

		let btns = document.querySelectorAll('[data-header-item]');
		let mobileCurrentBtn = document.querySelector('.js-mobile-active');
		let list = document.querySelector('.js-mobile-list');

		btns.forEach((btn) => {
			btn.addEventListener('click', () => {
				let active = document.querySelector('.trucks__slider-header-single--active');
				active.classList.remove('trucks__slider-header-single--active');
				btn.classList.add('trucks__slider-header-single--active');
				list.classList.remove('trucks__slider-header-wrap--show');
				this.changeSlider(btn);
			});
		});

		mobileCurrentBtn.addEventListener('click', () => {
			list.classList.toggle('trucks__slider-header-wrap--show');
		});

		this.initSlider(element);
	}

	initSlider(element){
		let parent = element.parentNode;
		let sliderID = parent.dataset.sliderId;

		this.photosSlider = new Swiper (element, {
			loop: true,
			speed: 1000,
			simulateTouch: false,
			mousewheel: false,
			navigation: {
				nextEl: '.js-next-autopark-'+sliderID,
				prevEl: '.js-prev-autopark-'+sliderID,
			}
		});

		this.photosSlider.on('slideChange', () => {
			let activeIndex = this.photosSlider.realIndex + 1;
			let counter = element.querySelector('.js-slider-count');
			counter.textContent = activeIndex;
		});
	}

	changeSlider(data){
		let sliders = document.querySelectorAll('[data-slider-id]');
		sliders.forEach((slider) => {
			slider.classList.remove('trucks__slider-single--active');
			if(slider.dataset.sliderId == data.dataset.headerItem){
				slider.classList.add('trucks__slider-single--active');
				let mobileActive = document.querySelector('.js-mobile-active');
				mobileActive.querySelector('span').textContent = data.textContent;
				this.photosSlider.update();
			}
		})
	}
}