export default class bannerVideo {
	constructor(element) {
		let video = element.querySelector('.js-video');
		let playVideoBtn = element.querySelector('.js-play-video');
		let closeModalBtn = document.querySelector('.js-video-close');

		playVideoBtn.addEventListener('click', () => {
			if(window.innerWidth > 1000){
				video.pause();
			}
			this.openModal(element);
		});

		closeModalBtn.addEventListener('click', () => {
			this.closeModal();
		});
	}

	openModal(element){
		let modal = document.querySelector('.js-video-modal');
		let video = document.querySelector('.js-modal-video');

		document.body.classList.add('hidden');
		document.querySelector('html').classList.add('hidden');
		modal.classList.add('modal--show');
		setTimeout(() => {
			modal.classList.add('modal--anim');
			setTimeout(() => {
				let content = document.querySelector('.js-video-modal-content');
				content.classList.add('modal__content--anim');
				setTimeout(() => {
					video.play();
				}, 500);
			}, 300);
		}, 50);
	}

	closeModal(){
		let modal = document.querySelector('.js-video-modal');
		let video = document.querySelector('.js-video');
		let videoModal = document.querySelector('.js-modal-video');

		modal.classList.remove('modal--anim');
		document.body.classList.remove('hidden');
		document.querySelector('html').classList.remove('hidden');

		videoModal.pause();
		videoModal.currentTime = 0;

		video.currentTime = 0;
		video.play();

		setTimeout(() => {
			let content = document.querySelector('.js-video-modal-content');
			modal.classList.remove('modal--show');
			content.classList.remove('modal__content--anim');
		}, 500);
	}
}