import axios from 'axios';

export default class cookies {
	constructor(element) {
		let cookiesAgreeBtn = document.querySelector('.js-cookies-agree');
		cookiesAgreeBtn.addEventListener('click', () => {
			this.agreeCookies(element);
		});
	}

	agreeCookies(element){
		element.classList.add('cookies--hide');

		axios({
			method: 'post',
			url: '/endpoint/cookies',
			data: {
				agree: true,
			}
		});
	}
}